<template>
  <v-content>
    <v-container fill-height fluid>
      <v-slide-y-transition mode="out-in">
        <router-view />
      </v-slide-y-transition>
    </v-container>
  </v-content>
</template>

<script>
export default {
  name: "GavcomFullPageView",

  components: {
    GavcomFullPageFooter: () => import("@/layouts/gavcom/full_page/Footer")
  },
};
</script>

<style>
  .no-padding{
    padding: 0 !important;
  }

  .no-padding-y{
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .no-padding-x{
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
</style>